
/* ### general ### */
* { margin: 0; padding: 0; box-sizing: border-box; -webkit-font-smoothing:antialiased; -webkit-text-size-adjust:none; }
html, body { font-size: 16px; color: #000; font-family: "Open Sans", sans-serif;}
body { background: #fff; font-family: "Open Sans", sans-serif; position: relative }
input, select, textarea { font-size: 16px; }
img { border: 0; display: block; }
ul, ol, li { list-style: none; margin:0 !important; padding: 0 !important; }
:focus { outline: none; }
a { transition: all 0.3s; text-decoration:none !important; }
p {  margin: 0; color: #000; margin-bottom:0; }
h1, h2, h3, h4, h5, h6  { margin: 0 !important;}
h1 { color: #000 !important; font-size: 24px !important; font-weight: 500 !important;}
h2 { font-size: 22px !important; font-weight: 500 !important;}

.blue-text {color: #044F8D;}
.button {width: 150px;height: 45px;text-align: center;background: #0096FF;text-decoration: none;color: #fff;border-radius: 40px;border: none;font-size: 16px; border: #0096FF solid 2px; transition: all ease 0.5s; display: flex; align-items: center;
justify-content: center;}
.button:hover {border: #0096FF solid 2px; color: #0096FF; background: none;  transition: all ease 0.5s;}

input { width: 100%; border-radius: 10px; border: #9DE2FF solid 1px; padding: 10px;}

input, select { font-family: "Open Sans", sans-serif; transition: all 0.3s; -webkit-transition: all 0.3s; }
textarea { font-family: "Open Sans", sans-serif; transition: all 0.3s; -webkit-transition: all 0.3s; }
input:focus, textarea:focus { outline: none; }
label { font-family: "Open Sans", sans-serif; font-weight: 500; display: block; padding-bottom: 10px; margin-bottom: 0}

.blue-bg {background: #0096FF;}

/* ### global classes ### */
.clear { clear: both; visibility: hidden; line-height: 0; height: 0; }
.center { margin: 0 auto; position: relative; max-width: 1280px; }

.centered { padding-right: calc((100% - 380px) / 2); padding-left: calc((100% - 380px) / 2); }
.container { max-width: 1040px !important;}

/* header-part */

.headerBox .langCol {
    background-image: linear-gradient(to right, #14a7e0, #3b2886);
}

.headerBox .langCol .top {
    padding-right: 0;
}

.headerBox .bottom .lang {
    width: 160px;
    height: 35px;
    border-radius: 10px;
    border: none;
    padding: 5px 10px;
    float: right;
}

/* form-part */

.formBox .inner {
    background: #F0FAFF;
    padding-left: 30px;
    padding-right: 30px;
}

.formBox .customerCol {
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: rgb(0 0 0 / 5%) 0px 4px 6px;
}

.formBox .customerCol h2 {
    color: #0096FF;
    padding-top: 10px;
}

.formBox .customerCol ul {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px !important;
}

.formBox .customerCol ul li {
    font-size: 18px;
    font-weight: 600;
    color: #000;
}

.formBox .customerCol ul li span {
    font-size: 16px;
    font-weight: 400;
    display: block;
}

.ratingCol {

}

.ratingCol h3 {
    font-size: 22px;
    color: #044F8D;
    font-weight: 700;
    padding-top: 50px;
}

.rate {
    padding-left: 20px;
}

.rate span {
    font-size: 16px;
    font-weight: 400;
    color: #044F8D;
    padding-top: 30px;
    display: block;
}

.rate ul {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    padding-top: 20px !important;
}

.rate ul li {
    /* margin-right: 20px !important; */  
    margin-right: -1px !important;
}

.rate ul li.active {
    opacity: 0.5;
}

/* .rate ul li figure {
    width: 50px;
    height: 50px;
    background: #fff;
    border: #9DE2FF solid 1px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
} */



.rate ul li b {
    display: block;
    font-weight: 500;
    color: #044F8D;
    font-size: 12px;
}

.captcha {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px !important;
}

.badQue {
    padding-left: 20px;
}

.badQue h4 {
    font-size: 16px;
    font-weight: 500;
    padding: 40px 0 20px;
}

.badQue .form-group {
    display: block;
    margin-bottom: 15px;
  }
  
.badQue .form-group input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
 .badQue .form-group label {
    position: relative;
    cursor: pointer;
    font-weight: 400;
  }
  
.badQue .form-group label:before {
    content: '';
    -webkit-appearance: none;
    border: 2px solid #D9D9D9;
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 15px;
    background: #fff;
    border-radius: 5px;
    margin-top: -2px;
  }
  
.badQue .form-group input:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 2px;
    left: 9px;
    width: 6px;
    height: 14px;
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.badQue .textareaCol textarea {
    width: 100%;
    height: 120px;
    border-radius: 10px;
    border: #9DE2FF solid 1px;
    padding: 10px;
}

.captcha input {
    width: 400px;
    height: 56px;
    margin-left: 30px;
}

.submitBtn {
    width: 210px !important;
    margin: 50px 0;
    float: right;
}

/* footer-part */

.footerBox {
    background: #3C2382;
    padding: 10px;
}

.footerBox p {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    margin: 0 !important;
}

/* Enter Mobile Page Start */

.mobileBox {
    background: #F0FAFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 100px;
    padding-bottom: 100px;
}

.mobileBox .right { 
    padding-right: 60px;
}

.mobileBox .right .inner {
    width: 100%;
    padding: 30px 50px;
    border-radius: 20px;
    background: #fff;
}

.mobileBox .right .inner h1 {
    font-weight: 700 !important;
    text-align: center;
}

.mobileBox .right .inner p {
    color: #044F8D;
    text-align: center;
    padding: 5px 0 20px;
}

.mobileBox .right .inner label {
    font-size: 14px;
}

.mobileBox .right .inner .mob {
    width: 100%;
    height: 56px;
    background: #F0FAFF;
    border: none;
}

.mobileBox .right .inner .button {
    margin:  70px auto 0;
}
.mobileBox .row {
	width:100%
}

/* OTP Page Start */

.otpInput {
    display: flex;
    justify-content: center;
    align-items: center;
}

.otpInput input {
    width: 50px !important;
    margin: 0 10px;
    text-align: center;
    font-size: 20px;
}

.resend {
    font-size: 14px;
    color: #000;
    font-weight: 400;
    margin-top: 25px;
}

.resend a {
    font-weight: 500;
    color: #044F8D;
}


/* QR Page Start */

.qrBox .qrText {
    border: #9DE2FF dotted 1px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 20px;
}

.qrBox .qrText hr {
    border-top: #9DE2FF dotted 1px;
    background: no-repeat;

}

.qrBox .inner {
    background: #F0FAFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 70px 20px 170px;
}

.qrBox .inner p {
    color: #044F8D;
}

.qrImg img {
    float: right;
}

/* QR Page Start */

.thankBox {
    padding-top: 100px;
    padding-bottom: 100px;
}

.thankBox img {
    margin:  0 auto;
}

.thankBox p {
    font-size: 30px;
    text-align: center;
    font-weight: 500;
    padding-top: 50px;
}

/* KIOS Page Start */

.kiosBox .inner {
    padding-top: 40px;
    padding-bottom: 40px;
}

.kiosText .button {
    width: 300px;
    height: 80px;
    text-align: center;
    margin: 20px auto;

}

.kiosText .button:hover {
    background: #0079bf;
    color: #fff;
}

/* SF Login Page Start */

.sfBox .inner{
    padding: 100px 60px;
}

.sf-text .button {
    margin: 0 auto;
}

.sf-text h1 {
    text-align: center;
    font-size: 28px !important;
    padding-bottom: 94px;
}


/* ### QR New Page CSS 02 Aug 2024 ### */

.qrBox .inner2 {

background-image: linear-gradient(to top, #14a7e0, #F0FAFF);
}

.whiteBg {
    background: #fff !important;
}

.footercol {
    display: flex;
    align-self: center;
    justify-content: space-between;
    background: #3C2382;
    padding-top: 10px;
    padding-bottom: 10px;
}

.footercol .call {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.footercol ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

.footercol ul li {
    margin-left: 5px !important;
}

.footercol ul li img,
.footercol ul li img a {
    transition: all ease 0.5s;;
}

.footercol ul li img:hover {
    border-radius: 20px;
}

/* ### QR New Page CSS END 02 Aug 2024 ### */


/* ### QR page 3 CSS END 04 Aug 2024  Start ### */

.qrHeader {
    width: 100%;

}

.qrPage .inner {
    background: url('../../public/images/blue-bg.png') left bottom no-repeat;
    background-size: 100%;
    padding: 0 15px;
}

.qrPage .inner .logo {
    display: block;
}

.qrPage .inner .logo img {
    display: block;
    float: right;
    margin: 20px 50px 0 0 ;
}

.qrPage .pBox {
    padding: 30px 50px 15px;
}

.qrPage .pBox p {
    font-size: 12px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 10px;
}

.qrPage .qouteIcon {
    position: relative;
}

.qrPage .qouteIcon::before {
    content: '';
    position: absolute;
    left: -30px;
    top: -15px;
    width: 26px;
    height: 26px;
    background: url('../../public/images/qoute.svg') left top no-repeat;
    background-size: cover;
}

.qrPage .qouteIcon::after {
    content: '';
    position: absolute;
    right: -15px;
    bottom: -10px;
    width: 26px;
    height: 26px;
    background: url('../../public/images/qoute.svg') left top no-repeat;
    background-size: cover;
    transform: rotate(180deg);
}

.qrGirl {
    position: relative;
}
.qrPage .qrGirl img {
    width: 170px;
    margin: 0px 0 0px 0px;
    position: absolute;
    bottom: 0;
}

.qrPage .qrCode {
    text-align: right;
}


.qrPage .qrCode img {
    margin: 0 auto;
}

.qrPage .qrCode p {
    color: #fff;
    padding-top: 20px;

}

.qrPage .qrCode p a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

.qrPage .qrCode p span {
    display: block;
    font-size: 13px;
    font-weight: 600
    ;
}

.footerBox1 .footercol {
    background: #00aeef;
    border-radius: 15px 15px 0  0;
    align-items: center;
}


/* Wrapper for QR code with white background */
.qr-wrapper {
   
    width: 150px;
    background-color: white;
    padding: 5px; 
    
    border-radius: 4px; 
    display: inline-block;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
  }
  
  /* Blue label styling */
  .blue-label {
    background-color: #007bff; /* Blue background color */
    color: white; /* White text color */
    padding: 5px; 
    border-radius: 4px; 
    text-align: center;
    margin-top: 10px; 
    font-weight: bold; 
    display: inline-block;
    font-size: 10px;
    width: 100%;
  }
  
  
  .qr-wrapper {
    background-color: white;
    padding: 10px; /* Adjust padding */
    border-radius: 4px; /* Optional: rounded corners */
    display: inline-block;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
    max-width: 100%;
    width: 82%;
  }
  
  .qrCode {
    text-align: center; /* Center-align content */
  }
  
  .blue-label {
    background-color: #007bff; /* Blue background color */
    color: white; /* White text color */
    padding: 5px; /* Adjust padding */
    border-radius: 4px; /* Optional: rounded corners */
    text-align: center; /* Center-align text */
    margin-top: 10px; /* Space between QR code and label */
    /* Optional: bold text */
    display: inline-block; /* Fit to content size */
  }

  
.footercol {
    display: flex;
    align-self: center;
    justify-content: space-between;
    background: #3C2382;
    padding-top: 10px;
    padding-bottom: 10px;
}

.footercol .call {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.footercol ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-start;
}

.footercol ul li {
    margin-left: 5px !important;
}

.footercol ul li img,
.footercol ul li img a {
    transition: all ease 0.5s;;
}

.footercol ul li img:hover {
    border-radius: 20px;
}
  .footerBox1 .footercol {
    background: #00aeef;
    border-radius: 15px 15px 0  0;
    align-items: center;
}






@media only screen and (max-width: 768px) {
		
    .container {
        width: 100% !important;
    }

    .headerBox .top img {
        width: 200px;
    }

    .kiosBox .inner {
        flex-wrap: wrap;
    }

    .kiosImg {
        width: 100% !important;
    }

    .kiosImg img {
        width: 300px;
        margin: 0 auto;
    }

    .kiosText {
        width: 100% !important;
    }


    .captcha {
        flex-wrap: wrap;
    }

    .captcha input {
        margin-left: 0 !important;
        width: 100%;
        margin-top: 20px;
    }

    .submitBtn {
        float: none;
    }

    .mobileBox {
        flex-wrap: wrap;
        padding-top: 30px;
    padding-bottom: 30px;
    }

    .mobileBox .left {
        width: 100%;
    }

    .mobileBox .right {
        width: 100%;
        padding-right: 0;
    }

    .mobileBox .left img {
        width: 300px;
        margin: 0 auto;
    }

    .qrBox .inner {
        padding: 30px 20px 40px;
    }

    .qrImg img {
        margin-top: 30px;
    }

    .sfBox .inner {
        padding: 50px 40px;
        flex-wrap: wrap;
    }

    .sfBox .inner .sfBox {
        width: 100%;
    }
    
    .sfBox .inner .sfBox img {
        width: 280px;
        margin: 0 auto;
    }

    .sfBox .inner .sf-text {
        width: 100%;
        margin-top: 40px;
    }

    .sf-text h1 {
        padding-bottom: 30px;
    }

    .formBox .inner {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* .rate ul li {
        margin-right: 10px !important;
    } */

    .rate {
        padding-left: 0;
    }

    .badQue {
        padding-left: 10px;
    }

    .rate ul li figure {
        width: 46px;
        height: 46px;
    }
}

.mobileBox .right .inner .mob {
margin: 0 0;}

.otpInput {
	width: 392px;
    flex-wrap: nowrap;}

.rate ul li figure {
        width: 90px;
        height: 90px;
    }
    
    .rate ul li figure img{
            width: 70px !important;
        height: 70px  !important;
    }
	
@media (max-width: 768px) {
	 
	 .otpInput {
	 width: 330px;
		}
        .rate ul li figure {
            width: 57px;
            height: 57px;
            padding: 5px;
            font-size: 42px;
        }
        
        .rate ul li figure img{
                width: 40px !important;
            height: 40px  !important;
        }    
        .ratingCol h3 {
            font-size: 18px;
        }
	 }

  .dpCode{
    margin-top: -5px !important;
    margin-bottom: 15px !important;
  }   
.emp-name{
    display: block;
    margin-bottom: 5px;
    border: 1px transparent;
    font-size: 16px;
}

/* changes for client review */
ul li figure {
    color: white;
    font-size: 62px;
    cursor: pointer;
}

.rate ul li figure.fig1 {
    background: #ED0B0D;
}
.rate ul li figure.fig2 {
    background: #F87501;
}
.rate ul li figure.fig3 {
    background: #F8DE01;
}
.rate ul li figure.fig4 {
    background: #CCDE0E;
}
.rate ul li figure.fig5 {
    background: #44BC37;
}