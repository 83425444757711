@import url('https://fonts.cdnfonts.com/css/lato');

body {
    margin: 0;
    padding: 0;
	font-family: "Lato";
}
.main_container1 {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.container1 {
    max-width: 590px;
    margin: 0 auto;
}
.header1 {
    
	background: #1466aa;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header1 img.logo_icon {
    max-width: 10%;
    border-radius: 50%;
    border: 1px solid #fff;
    margin: 20px;
    height: 10%;
}
img.logo {
    width: 70%;
    padding: 10px 20px 0px 20px;
}
.header1 span {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.header1 span h4 {
    text-align: right;
    width: 50%;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0px 25px 0px 25px;
    color: #fff;
	    z-index: 1;
}

.content_style {
width: 100%;
    height: auto;
    background: #fec210  url('../../public/formimages/border_back1.png') top left;
    padding: 10px 20px;
    box-sizing: border-box;
	background-position: top;
	background-repeat:no-repeat;
    min-height: 706px;
}
.content_style1 {
width: 100%;
    height: auto;
    background: #fff;
    padding: 0px;
    box-sizing: border-box;
}
.content_style1 img {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: -20px;
    border-bottom: 1px solid rgb(229 229 229 / 65%);
    border-top: none;
}


.content_box {
width: 100%;
height:auto;
    justify-content: space-between;
    display: flex;
	    padding-top: 10px;
		flex-direction: row;
    align-items:flex-start;
}


.content_box h2 {
    color: #fff;
    font-size: 30px !important;
    padding-left: 10px;
    text-transform: uppercase;
    margin: 0;
    font-weight: 400 !important;
    line-height: 1.2;
    padding-bottom: 10px;
    /* padding-top: 10px; */
}
.content_style h2 strong {
font-size: 40px;
    font-weight: 700;
}
.content_style h4 strong {
    font-size: 18px;
    font-weight: 600;
    padding: 5px 20px;
    line-height: 2.3;
}

.content_box .scan {
    min-width: 260px;
    height: 260px;
    background: transparent;
    border: 2px solid #000;
    padding: 6px;
    box-sizing: border-box;
}
.content_box .scan svg{
    border: 2px solid #000;
    max-width: 175px;
}
.content_box .scan img {
    border: 2px solid #000;
	max-width:220px;
}
.content_box .scan h4 {
font-size: 16px;
    text-align: center;
    margin: 0;
    padding: 12px 0px;
    text-transform: uppercase;
}
.content_box span {
    font-size: 28px;
    line-height: 1.3;
    font-weight: 400;
    margin: 0;
    padding: 10px 35px 5px 15px;
    /* min-height: 170px; */
}

.content_box span h4 {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 400;
    padding: 25px;
	margin:0;
	padding:10px 0px;
	    display: flex;
    align-items: baseline;
}
.cust_txt img, .content_box h4 img {
    vertical-align: middle;
    padding: 0px 6px;
    max-width: 32px;
}

h2.cust_txt {
    font-size: 16px !important;
    color: #000;
    font-weight: 600 !important;
    text-transform: none;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    align-self: center;
}

h2.cust_txt strong {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    text-transform: none;
    line-height: 1.3;
}
img.cust_img {
    max-width: 160px;
}

.footer1 {
    background: #00aeef;
    padding: 5px 20px;
    width: 100%;
    box-sizing: border-box;
	    display: flex;
    align-items: center;
    justify-content: center;
}

.footer1 ul {
    width: auto;
    align-items: center;
    margin: 0;
    padding: 7px 0px;
    list-style: none;
    display: flex;
}

/* .footer1 ul {
    margin: 0;
    padding: 7px 0px;
    list-style: none;
	display:flex;
} */

.footer1 ul li {
    font-size: 12px;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.footer1 ul li img {
    max-width: 18px;
    vertical-align: bottom;
    padding: 2px;
}
.footer1 ul li img.bank_styl {
    max-width: 35px;
}
.footer1 span {
    font-size: 21px;
    color: #fff;
    padding: 0px 5px;
}
.footer1 ul li img.lang_styl {
    max-width: 30px;
    padding-left: 6px;
}

.centered1{
    padding: none !important;
    width: 100%;
}
.b-none{
    background: none !important;
}
img.quoteright{
    margin-top: -20px;
    margin-left: 352px;
}
img.quoteleft {
    left: -30px;
    bottom: -15px;
    position: relative;
}
